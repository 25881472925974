import bullet from "../../../assets/icons/bullet.svg";
import PropTypes from "prop-types";

const ClaimAudit = ({ data }) => {
  // Format the date to a readable format
  const formattedDate = new Date(data.createdAt).toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return (
    <div className="flex text-gray-500 text-xs gap-2 my-5 items-center">
      <div className="text">
        <img src={bullet} alt="bullet" className="text" />
      </div>
      <div className="text flex justify-between w-full items-center">
        <div className="text">{data.description}</div>
        <div className="text font-medium">{formattedDate}</div>
      </div>
    </div>
  );
};

ClaimAudit.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string, 
  }).isRequired,
};

export default ClaimAudit;
