import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../components/shadch/ui/ui/sheet";
import tablarFile from "../../../assets/icons/tabler_file-invoice.svg";
import { useEffect, useRef, useState } from "react";
import searchImg from "../../../assets/icons/Search.svg";
import { IoFilterSharp } from "react-icons/io5";
import ClaimFileCard from "./ClaimFileCard";
import { ScrollArea } from "../../../components/shadch/ui/ui/scroll-area";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../components/shadch/ui/ui/form";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/shadch/ui/ui/input";
import attachedFileIcon from "../../../assets/icons/attach.svg";
import { FiDelete } from "react-icons/fi";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/shadch/ui/ui/tooltip";
import { Button } from "../../../components/shadch/ui/ui/button";
import ClaimAudit from "./ClaimAudit";
import apiClient from "../../../http/common";
import { toast } from "react-toastify";

const OpenfileModal = ({ text, claimId, isOpen, setIsOpen }) => {
  const [tabs, setTabs] = useState(1);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [claimFile, setClaimFile] = useState(null);
  const [claimAudit, setClaimAudit] = useState(null);
  const [claimIsLoading, setClaimIsLoading] = useState(true);
  const [claimAuditIsLoading, setClaimAuditIsLoading] = useState(true);
  const fileInputRef = useRef(null);

  const form = useForm({
    defaultValues: {
      message: "",
      file: null,
    },
  });

  const statusEnum = {
    Submitted: 0,
    InReview: 1,
    Invalid: 2,
    Valid: 3,
  };

  
    const fetchClaimFile = async () => {
      try {
        const response = await apiClient.get(
          `/sponsor/GetFilesForManualClaim?manualClaimId=${claimId}`
        );
        setClaimFile(response?.data?.data?.comments);
      } catch (error) {
        console.error("Error fetching claim files:", error);
      } finally {
        setClaimIsLoading(false);
      }
    };
    const fetchClaimAudit = async () => {
      try {
        const response = await apiClient.get(
          `/sponsor/GetAuditTrailForClaim?manualClaimId=${claimId}`
        );
        setClaimAudit(response?.data?.data);
      } catch (error) {
        console.error("Error fetching claim audit trail:", error);
      } finally {
        setClaimAuditIsLoading(false);
      }
    };
useEffect(() => {
  fetchClaimFile();
  fetchClaimAudit();

}, [claimId])


  const handleAttachClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!allowedTypes.includes(file.type)) {
        toast.error("Unsupported file type. Please upload a JPG, PNG, or PDF.");
        return;
      }
      if (file.size > maxSize) {
        toast.error("File size exceeds 5MB limit.");
        return;
      }

      form.setValue("file", file);
      setSelectedFileName(file.name);
    }
  };
  const refetchClaimFiles = () => {
    fetchClaimFile();
  };


  const onSubmit = async (data) => {
    const getValues = form.getValues();
    setIsOpen(false);

    const formData = new FormData();
    formData.append("Comment", getValues.message);
    formData.append("file", getValues.file);
    formData.append("NewStatus", statusEnum[text]);
    formData.append("ManualClaimId", claimId);

    try {
      const response = await apiClient.post(`/sponsor/AddClaimComment`, formData);
      toast.info(response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
      });
      form.reset();
      setSelectedFileName("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      await refetchClaimFiles();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <button className="text-white flex bg-[#2CBCEF] px-3 p-1 rounded-lg items-center gap-2">
          <img src={tablarFile} alt="" className="text w-5" />
          Open File
        </button>
      </SheetTrigger>
      <SheetContent className="p-0 pt-16 border-gray-700 min-w-[40%] max-w-1/2 rounded-tr-lg rounded-tl-lg">
        <SheetHeader className={"shadow-sm p-3 border-b"}>
          <SheetTitle>
            <div className="text">
              <ul className="text-sm gap-3 flex">
                <li>
                  <button
                    onClick={() => setTabs(1)}
                    className={`${
                      tabs === 1
                        ? "underline decoration-2 underline-offset-4 decoration-[#2CBCEF]"
                        : "text-gray-500"
                    }`}
                  >
                    File
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setTabs(2)}
                    className={`${
                      tabs === 2
                        ? "underline decoration-2 underline-offset-4 decoration-[#2CBCEF]"
                        : "text-gray-500"
                    }`}
                  >
                    Audit Trail
                  </button>
                </li>
              </ul>
            </div>
          </SheetTitle>
          <SheetDescription>
            <div className="text flex justify-between items-center my-4 relative">
              <IoFilterSharp onClick={() => setOpenSearch(!openSearch)} />
              <img
                src={searchImg}
                alt="search"
                className="absolute w-4"
                onClick={() => setOpenSearch(!openSearch)}
              />
              <Input
                type="search"
                className={`bg-gray-50 ${
                  openSearch ? "w-full px-8 rounded-lg" : "w-0 h-0 p-0"
                }`}
              />
            </div>
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="h-[68vh] px-3 lg:px-6 min-h-[65vh] bg-gray-50 w-full">
          {tabs === 1 && (
            <div>
              {claimIsLoading ? (
                <p>Loading claim files...</p>
              ) : (
                claimFile?.map((value) => <ClaimFileCard key={value.name} data={value} />)
              )}
            </div>
          )}
          {tabs === 2 && (
            <div>
              {claimAuditIsLoading ? (
                <p>Loading audit trail...</p>
              ) : (
                claimAudit?.map((value) => <ClaimAudit key={value.name} data={value} />)
              )}
            </div>
          )}
        </ScrollArea>
        <div className="text p-3">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid grid-cols-9 border rounded-lg p-2"
            >
              <div
                className={`text ${selectedFileName ? "col-span-4" : "col-span-6"} `}
              >
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="Write a message"
                          className="border-0 shadow-none p-0"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {selectedFileName && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="text mx-1 rounded-lg col-span-2 gap-1 flex justify-center items-center bg-[#2CBCEF1A] relative">
                        {selectedFileName?.slice(0, 4)}...
                        <FiDelete
                          onClick={() => {
                            fileInputRef.current.value = "";
                            setSelectedFileName("");
                          }}
                          className="text-red-500 cursor-pointer"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{selectedFileName}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <div
                onClick={handleAttachClick}
                className="text flex cursor-pointer justify-center bg-[#F8F8FA] items-center w-8 h-8 rounded-full relative"
              >
                <Input
                  accept=".pdf,.jpg,.jpeg,.png"
                  className="border-0 shadow-none p-0 hidden"
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <img src={attachedFileIcon} alt="" className="text" />
              </div>
              <div className="text-white h-10 col-span-2 bg-[#2CBCEF] rounded-lg">
                <Button className="w-full h-full py-2">Send</Button>
              </div>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default OpenfileModal;
